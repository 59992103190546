import React from 'react'
import { useTranslation } from 'react-i18next'

export default function Contact() {
  const { t } = useTranslation()

  return (
    <div id="contact" className="contact i18n-he" style={{margin: 'auto'}}>
      {/* <div data-aos="fade-up">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3300.8800000000005!2d34.9913641!3d32.8188677!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151dbb11dff03a89%3A0xa43133e8ffb83bec!2sBBQ%20HOUSE!5e0!3m2!1sen!2sil!4v1631000000000!5m2!1sen!2sil"
          width="100%"
          height="450"
          style={{ border: 0 }}
          loading="lazy"
        ></iframe>
      </div> */}

      <div className="" data-aos="fade-up">
        <div className="row" style={{margin: 'auto'}}>
          <div className="info">
            <div className="clickable">
              <a
                className="address"
                href="https://www.google.com/maps/dir//'32.8319782,35.4074556'/@32.8319464,35.3660115,13z/data=!4m6!4m5!1m0!1m3!2m2!1d35.4074556!2d32.8319782?entry=ttu"
                target="_blank"
                rel="noreferrer"
              >
                <i className="bi bi-geo-alt"></i>
                <h4>{t('contact-location-title')}</h4>
                <p>{t('contact-location-data')}</p>
              </a>
            </div>

            <div className="clickable">
              <a href="mailto:shabaita.realestate@gmail.com" className="email">
                <i className="bi bi-envelope"></i>
                <h4>{t('contact-email-title')}</h4>
                <p>shabaita.realestate@gmail.com</p>
              </a>
            </div>

            <div className="clickable">
              <a className="phone" href="tel:+972525343341">
                <i className="bi bi-phone"></i>
                <h4>{t('contact-call-title')} - וארד</h4>
                <p>052 - 5343341</p>
              </a>
            </div>

            <div className="clickable">
              <a className="phone" href="tel:+9725343342">
                <i className="bi bi-phone"></i>
                <h4>{t('contact-call-title')} - ענאן</h4>
                <p>052 - 5343342</p>
              </a>
            </div>

            <div className="clickable">
              <a className="phone" href="tel:+97246891290">
                <i className="bi bi-pc-display-horizontal"></i>
                <h4>{t('contact-fax-title')}</h4>
                <p>04 - 6891290</p>
              </a>
            </div>

            {/* <div>
              <div className="open-hours">
                <i className="bi bi-clock"></i>
                <h4>{t('contact-opening-hours-title')}</h4>
                <p>{t('contact-opening-hours-data')}</p>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}
