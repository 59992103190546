import React from 'react'
import { useTranslation } from 'react-i18next'

export default function About() {
  const { t } = useTranslation()
  const paraNumberStyle = 
    {
      fontSize: 24, 
      marginBottom: '2rem',
       fontWeight: 700, 
       color: '#cda45e',  
        border: '2px solid #cda45e',
        minWidth: 44,
        height: 44,
        textAlign: 'center',
        lineHeight: '44px',
        borderRadius: 50,
        marginLeft: 10,
        display: 'inline'
      }

  return (
    <section id="about" className="about i18n-he">
        <div className="container d-flex i18n-he align-items-center">
      <div className="d-flex" data-aos="fade-in" style={{fontWeight: 700, marginBottom: '4rem'}} data-aos-delay="100"><h1 style={{fontWeight:700}}>
      מסלולים לבנות הממ״ד </h1>
      <h1 style={{marginLeft: 8, fontWeight:700}}>2</h1></div>
      </div>
      <div className="container d-flex i18n-he align-items-center">
      <div className="col-md-6" data-aos="fade-up" data-aos-delay="100">
        <div data-aos="fade-in" style={{fontSize: 32, marginBottom: '1rem', fontWeight: 700, color: '#cda45e', display: 'flex', flexDirection:'row-reverse', paddingTop: '2rem'}}>
         <h1 style={paraNumberStyle}>1</h1>
         <h1 style={{display: 'inline'}}>{'מסלול פטור מהיתר בנייה'} </h1>
        </div>
        <h2 className="i18n-he" data-aos="fade-in" data-aos-delay="100">
                  {t('about-para3-title')}
                </h2>
                  <p
            style={{ fontSize: 22, padding: '0.25rem 0 0.25rem', marginBottom: 0}}
            data-aos="fade-up" data-aos-delay="300"
          >
           {t('about-para3-subtitle1')} ●
          </p>
          <p mb={0}
          style={{ fontSize: 22, padding: '0.25rem 0 0.25rem', marginBottom: 0}}
            data-aos="fade-up" data-aos-delay="600"
          >
            {t('about-para3-subtitle2')} ●
          </p>

        </div>
        <div className="col-md-6 " data-aos="fade-in" data-aos-delay="200">
          <img src="assets/img/house2.jpg" className="img-fluid" alt="" />
        </div>
      </div>

      <div className="container d-flex i18n-he align-items-center">
      <div className="col-md-12 " data-aos="fade-in" data-aos-delay="200">
          <div
            style={{ fontSize: 16, marginTop: '2rem' }}
          >
            <p>
            לא משאירים אף אחד לא מוגן - בנייה מהירה של ממ"ד 9 מ"ר בבית קיים
            </p>
            <p style={{fontWeight: 700, fontSize: 24}}>:
            השירות שלנו כולל
            </p>
            <p>
            {t('about-para2-2')}
            </p>
            <p style={{fontWeight: 700, fontSize: 24}}>:
            הממ״ד כולל
            </p>
            {/* {t('about-para2-li1')} ●<br /> */}
            {t('about-para2-li2')} ●<br />
            {t('about-para2-li3')} ●<br />
            {t('about-para2-li4')} ●<br />
            {t('about-para2-li5')} ●<br />
            {t('about-para2-li6')} ●<br />
            {t('about-para2-li7')} ●<br />
            {t('about-para2-li8')} ●<br />
            {t('about-para2-li9')} ●<br />
            {t('about-para2-li10')} ●
          </div>
        </div>
      </div>

      <div className="container d-flex i18n-he align-items-center mt-4 mb-4">

        <div className="col-md-7 " data-aos="fade-up" data-aos-delay="100">
            <p className={'mt-4'} data-aos="fade-up" data-aos-delay="200">{'אם המסלול הראשון לא מתאים לכם אל תדאגו! המסלול השני יתאים'}</p>
 
        <div data-aos="fade-in" style={{fontSize: 32, marginBottom: '1rem', fontWeight: 700, color: '#cda45e', display: 'flex', flexDirection:'row-reverse', paddingTop: '2rem', paddingRight: 0}}>
         <h1 style={paraNumberStyle}>2</h1>
         <h1 style={{display: 'inline'}}>{'מסלול היתר בנייה מזורז'} </h1>
        </div>
       <p>מסלול זה כולל את כל הדברים במסלול פטור מהיתר בנייה, שני ההבדלים היחידים הם תקופת זמן נוספת עד תחילת הבנייה בגלל בקשת היתר הבנייה.
הדבר השני הוא מחיר נוסף אודות הגשת מסמכי היתר הבנייה</p>
        </div>
        <div className="col-md-5" data-aos="fade-up" data-aos-delay="100" style={{padding: '1rem'}}>
             <img src="assets/img/house1.jpg" className="img-fluid" alt="" />
        </div>
      </div>
    </section>
  )
}
