import React from 'react'

export default function ContactMap() {
  return (
    <section id="contact" className="contact i18n-he">
      <div data-aos="fade-up">
       <iframe
        title="map"
       src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3352.501227828335!2d35.404880675664934!3d32.831978173640664!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzLCsDQ5JzU1LjEiTiAzNcKwMjQnMjYuOCJF!5e0!3m2!1sen!2sil!4v1699719594288!5m2!1sen!2sil" 
       width="100%"
        height="450"
        style={{ border: 0 }}
        allowfullscreen=""
        loading="lazy"
       referrerpolicy="no-referrer-when-downgrade">

       </iframe>
      </div>
    </section>
  )
}
