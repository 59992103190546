import { initializeApp } from 'firebase/app';
// import { getFirestore } from 'firebase/firestore';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyBPwa20N8XpxSGYq8ecD0EGQxjoMGpaVds",
  authDomain: "shabaitarealestate-68743.firebaseapp.com",
  projectId: "shabaitarealestate-68743",
  storageBucket: "shabaitarealestate-68743.appspot.com",
  messagingSenderId: "73915777269",
  appId: "1:73915777269:web:6bb83bbdc9d11d021905bc",
  measurementId: "G-94PXFD17MY"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };