import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Contact from './Contact'
import { db } from '../firebase';
import { collection, addDoc } from "firebase/firestore";
import { Alert, AlertTitle } from '@mui/material';
import { Alert as AlertLab } from '@mui/lab';

export default function ContactForm() {
  const { t } = useTranslation()
  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');


  const closeAlert = () => {
    setSubmissionSuccess(false);
    setErrorMessage('');
  };

  const handleAlertClose = () => {
    closeAlert();
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setSubmissionSuccess(false);
    }, 5000); // Close the alert after 5 seconds

    return () => clearTimeout(timer);
  }, [submissionSuccess, errorMessage]);

  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    content: '',
    location: ' ',
    start_date: ' ',
    comments: ' ',
  })

  const [errors, setErrors] = useState({})

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
    // Clear error when the user starts typing
    if (errors[e.target.name]) {
      setErrors({ ...errors, [e.target.name]: '' })
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {};

    // Validate each required field
    if (!formData.name) newErrors.name = true;
    if (!formData.phone) newErrors.phone = true;
    if (!formData.email) newErrors.email = true;

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {

      try {
        const { name, phone, email, content, location, start_date, comments } = formData;
        const contactFormData = {
          name,
          phone,
          email,
          content,
          location,
          start_date,
          comments,
          timestamp: new Date(),
        };

        const docRef = await addDoc(collection(db, 'contact-form'), contactFormData);
        if(docRef.id){
          setSubmissionSuccess('תודה שפניתם אלינו! נחזור אליך בקרוב')
          window.location.href = window.location.href + '?thankyou';
        }

        setFormData({
          name: '',
          phone: '',
          email: '',
          content: '',
        });
      } catch (error) {
        const errorMessage = 'התרחשה שגיאה במהלך השליחה. אנא נסו שוב';
        setErrorMessage(errorMessage);
      }
    }
  };

  return (
    <section id="contact" className="about i18n-he">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>{t('contact-title')}</h2>
          <p>{t('contact-subtitle')}</p>
          <p style={{color: '#fff', fontSize: 18, fontWeight: 400, marginTop: 4}}>הזדרזו והשאירו את פרטיכם ע״מ להבטיח את מקומכם בין הראשוניים</p>
          <p style={{color: '#fff', fontSize: 18, fontWeight: 400}}>אנחנו מקבלים רק 10 פרויקטים ממ״דים בחודש</p>
        </div>
      </div>

      <div className="container d-flex">
        <div style={{ width: '30%' }} data-aos="fade-in" data-aos-delay="100">
          <Contact />
        </div>
        <div style={{ width: '10%' }}></div>
        <div style={{ width: '60%' }} data-aos="fade-in" data-aos-delay="100">
          {(submissionSuccess || errorMessage) && (
          <div className='mb-4'>
        {submissionSuccess ? (
          <Alert severity="success" onClose={handleAlertClose}>
            <AlertTitle>
            תודה שפניתם אלינו! נחזור אליך בקרוב
            </AlertTitle>
          </Alert>
        ) : (
          errorMessage && (
            <AlertLab severity="error" onClose={handleAlertClose}>
              <AlertTitle>  {errorMessage}</AlertTitle>
            </AlertLab>
          )
        )}
        </div>
          )}
          <div className="form-container" data-aos="fade-up">
            <form onSubmit={handleSubmit}>
              <input
                // required
                className={`form-input ${errors.name ? 'error' : ''}`}
                type="text"
                name="name"
                placeholder="שם מלא"
                value={formData.name}
                onChange={handleChange}
              />
              <input
                // required
                className={`form-input ${errors.name ? 'error' : ''}`}
                type="tel"
                name="phone"
                placeholder="מס' טלפון"
                value={formData.phone}
                onChange={handleChange}
              />
              <input
                // required
                className={`form-input ${errors.name ? 'error' : ''}`}
                type="email"
                name="email"
                placeholder='דוא"ל'
                value={formData.email}
                onChange={handleChange}
              />
              <textarea
                className="form-textarea"
                name="content"
                placeholder="הערות"
                value={formData.content}
                onChange={handleChange}
              />
              <button className="form-button" type="submit">
              שלח
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}
