import React from 'react'
import { useTranslation } from 'react-i18next'

export default function Hero() {
  const { t } = useTranslation()
  return (
    <section id="hero" className="d-flex align-items-center">
      <div className="container position-relative text-center text-lg-start i18n-he">
        <div className="row i18n-he">
          <div className="col-lg-12">
            <div className="d-flex align-items-center justify-content-end">
              <h2 data-aos="fade-in" data-aos-delay="100">
              <span>{t('hero-title1')}</span>
              <br />
                {t('hero-title2')}
              </h2>
              <img
                data-aos="fade-in"
                data-aos-delay="1200"
                data-aos-duration="600"
                src="assets/img/logo-no-bg.png"
                alt=""
                className="img-fluid"
                style={{ width: 148, marginLeft: 24 }}
              />
            </div>
            <h1
              data-aos="fade-in"
              data-aos-delay="300"
              style={{ marginTop: 24 }}
            >
              {t('hero-subtitle')}
            </h1>

            <div className="d-flex i18n-he">
              <div className="col-lg-8 who-are-we">
                <h2 className="i18n-he" data-aos="fade-up" data-aos-delay="300">
                  {t('about-para1-title')}
                </h2>
                <p
                  className="py-4"
                  data-aos="fade-up"
                  data-aos-delay="600"
                  data-aos-duration="700"
                >
                  {t('about-para1')}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
